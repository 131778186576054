import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { Spaces, mainBox } from "../../../assets/Variables/SpaceVariables";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import TableComponent from "../../Generals/Tables/TableComponent";
import TableToolTip from "../../Generals/Tooltips/TableToolTip";
import IconComponent from "../../Generals/Icons/IconComponent.js";
import SearchBoxComponent from "../../Generals/SearchBox/SearchBoxComponent";
import backgroundImage from "../../../assets/logos/noContentFollower.svg";
import LoadingSpinner from "../../Generals/Loaders/SpinLoader";
import signalService from "../../../services/signalProvider";
import Button3px from "../../Generals/Buttons/3pxButton";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import NotificationModal from "../../Generals/Notifications/NotificationModal";
import { useDynamicStyles } from "./Styles";
import { useBreakpoint } from "../../../config/BreakpointContext.js";
import StatusModule from "../../Generals/Cards/StatusModule";

function SignalFollowesTable(props) {
  //#region "Instantiation of States"
  //-----------------------------------------------------------
  //Declaration of constants, variables and hooks
  //-----------------------------------------------------------
  const { isXs, isMd } = useBreakpoint();
  const styles = useDynamicStyles();
  const { t } = useTranslation("global");
  const [totalAmount, SetTotalAmount] = useState();
  const [page, setPage] = useState(1); // Estado para la página actual
  const [pageCount, setPageCount] = useState(2); // Estado para el número de páginas
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]); // Estado para los datos de las filas
  const [searchTerm, setSearchTerm] = useState("");
  const [modalProps, setModalProps] = useState({
    open: false,
    iconType: "",
    title: "",
    message: "",
    onAcceptLabel: undefined,
    onAccept: undefined,
  });

  const columns = [
    {
      field: "follower_login",
      headerClassName: "header_table",
      renderHeader: () => <>{"Follower"}</>,
    },
    {
      field: "name",
      headerClassName: "header_table",
      renderHeader: () => <>{"Follower name"}</>,
    },
    {
      field: "copy_mode",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="Copy method initiated by the Signal Provider account.">
          <Box sx={styles.tooltipBox}>
            {"Copy mode "}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
    {
      field: "min_lot_size",
      headerClassName: "header_table",
      renderHeader: () => <>{"Min. lot size"}</>,
    },
    {
      field: "max_lot_size",
      headerClassName: "header_table",
      renderHeader: () => <>{"Max. lot size"}</>,
    },
    {
      field: "risk_ratio",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="The relationship between potential risk and potential return.">
          <Box sx={styles.tooltipBox}>
            {"Risk ratio "}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
    {
      field: "fixed_lots",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="The set number of lots that are executed with each trade.">
          <Box sx={styles.tooltipBox}>
            {"Fixed lot amt. "}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
    {
      field: "drawdown_mode",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="The setting that limits the maximum loss your account can tolerate.">
          <Box sx={styles.tooltipBox}>
            {"DD protection "}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
    {
      field: "max_drawdown",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="The max amount of drawdown.">
          <Box sx={styles.tooltipBox}>
            {"Max. DD "}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
  ];
  //#endregion

  //#region "Handlers"
  const handleChangePage = (page) => {
    setPage(page);
    RequestFollowersList(10, page);
  };

  const handleSearch = () => {
    setPage(0);
    RequestFollowersList(10, 0, searchTerm);
  };

  const handleSearchTerm = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const handleCloseNotification = (prevModalProps) => {
    setModalProps({ ...prevModalProps, open: false });
  };
  //#endregion

  //#region "Requests"
  const RequestFollowersList = async (quantity, page, search = "") => {
    try {
      setLoading(true);
      const response = await signalService.getSlaves(quantity, page, search);
      if (response.data != null) {
        setLoading(false);
        let actualRows = response.data.data.data.map((log, index) => {
          let row = {
            id: index,
            follower_login: log.follower_login,
            signal_followed: log.signal_followed,
            name: log.name,
            leverage: log.leverage,
            copy_mode: log.copy_mode,
            risk_ratio:
              log.copy_mode === "Proportional" ? log.risk_ratio : "N/A",
            fixed_lots: log.copy_mode === "FixedLots" ? log.risk_ratio : "N/A",
            drawdown_mode:
              log.drawdown_mode === "NoDrawdown" ? "No drawdown" : "In equity",
            max_drawdown: `$${log.max_drawdown}`,
            min_lot_size:
              log.copy_mode === "FixedLots" ? log.min_lot_size : "N/A",
            max_lot_size:
              log.copy_mode === "FixedLots" ? log.max_lot_size : "N/A",
            following_date: log.following_date,
            modification_date: log.modification_date,
            connect: log.connect,
            currency: log.currency,
          };
          return row;
        });
        setRows(actualRows);
        setPageCount(response.data.data.total_pages);
        SetTotalAmount(response.data.data.total_registers);
      } else {
        setLoading(false);
        SetTotalAmount(0);
      }
    } catch (error) {
      console.log(error);
      SetTotalAmount(0);
      setLoading(false);
      if (
        error.response.data.message ===
        "Your account does not have followers at the moment"
      ) {
      } else {
        setModalProps({
          open: true,
          iconType: "error",
          title: "Server error",
          message:
            "Error loading strategy information, please contact an administrator",
          onAcceptLabel: "Continue",
          onAccept: () => handleCloseNotification(),
        });
      }
    }
    setLoading(false);
  };
  //#endregion

  //#region "useEffects"

  useEffect(() => {
    if (searchTerm === "") {
      RequestFollowersList(10, page);
    }
  }, [searchTerm]);

  useEffect(() => {
    RequestFollowersList(10, page);
  }, []);

  //#endregion

  //#region "HTML"
  return (
    <Box sx={mainBox}>
      {loading ? <LoadingSpinner /> : <></>}
      <NotificationModal
        open={modalProps.open}
        onClose={handleCloseNotification}
        iconType={modalProps.iconType}
        title={modalProps.title}
        message={modalProps.message}
        onAcceptLabel={modalProps.onAcceptLabel}
        onAccept={modalProps.onAccept}
      />
      {isXs && <StatusModule></StatusModule>}

      <Paper
        elevation={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 4,
          width: isMd ? "calc(100vw - 280px)" : "95vw",
          maxWidth: "2000px",
          borderRadius: "12px",
          gap: Spaces.Size_6,
        }}
      >
        <Typography sx={{ fontFamily: TextVariables.Body_3_Bold }}>
          My followers
        </Typography>
        {(rows.length > 0 || searchTerm !== "") && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              gap: Spaces.Size_6,
            }}
          >
            <Box sx={styles.searchContainer}>
              <SearchBoxComponent
                placeholderprops = {"Search by: login or name"}
                handleSearchTerm={handleSearchTerm}
                value={searchTerm}
              ></SearchBoxComponent>
              <Button3px
                label={"Search"}
                color={"White"}
                backgroundColor={Colors.Error_500}
                HandleOnClick={handleSearch}
              ></Button3px>
            </Box>
            <Box sx={{ width: "100%", marginTop: "30px", overflowX: 'auto'}}>
              <Box sx={{ minWidth: "1120px" }}>
                <TableComponent
                  rows={rows}
                  page={page}
                  totalAmount={totalAmount}
                  pageCount={pageCount}
                  handleChangePage={handleChangePage}
                  columns={columns}
                />
              </Box>
            </Box>
          </Box>
        )}
        {totalAmount <= 0 && searchTerm === "" && (
          <Box sx={styles.performanceBox}>
            <img
              alt="background empty"
              src={backgroundImage}
              style={{
                mixBlendMode: "luminosity",
                width: isMd ? 350 : "80%",
                maxWidth: "350px",
                opacity: 0.8,
              }}
            />
            <Typography sx={{ fontFamily: TextVariables.Caption_1 }}>
              You don’t have followers yet.
            </Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
  //#endregion
}

export default SignalFollowesTable;
