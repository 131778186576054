import React, { createContext, useContext } from 'react';

const BreakpointContext = createContext();
const StatusContext = createContext();

// Hook para acceder al contexto desde los componentes hijos
export const useBreakpoint = () => useContext(BreakpointContext);
export const useStatus = () => useContext(StatusContext);

export const BreakpointProvider = ({ children, value }) => {
  return (
    <BreakpointContext.Provider value={value}>
      {children}
    </BreakpointContext.Provider>
  );
};

export const StatusProvider = ({ children, value }) => {
  return (
    <StatusContext.Provider value={value}>
      {children}
    </StatusContext.Provider>
  );
};
