// encryption.js
import CryptoJS from 'crypto-js';

export const encrypt = (data) => {
  return CryptoJS.AES.encrypt(data, process.env.REACT_APP_SECRET_KEY).toString();
};

export const decrypt = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const makeUrlSafe = (base64String) => {
  return base64String
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
};

// Función para revertir el proceso URL-safe
export const revertUrlSafe = (urlSafeString) => {
  let base64String = urlSafeString
    .replace(/-/g, '+')
    .replace(/_/g, '/');
  while (base64String.length % 4) {
    base64String += '=';
  }
  return base64String;
};
