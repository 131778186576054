import React from "react";
import { useTranslation } from "react-i18next";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import { Typography, Box } from "@mui/material";
import { useBreakpoint } from "../../../config/BreakpointContext.js";
import { useDynamicStyles } from "./Styles.js";

// Registrar los elementos necesarios de chart.js
ChartJS.register(ArcElement, Tooltip, Legend, Title);

const HalfGaugeChart = ({ riskValue }) => {
  const { issXs, isXs, isMd } = useBreakpoint();
  const styles = useDynamicStyles();
  console.log(issXs);
  const maxValue = 10;
  const [t] = useTranslation("global");
  const data = {
    labels: Array.from({ length: maxValue }, (_, i) => (i + 1).toString()),
    datasets: [
      {
        data: Array.from({ length: maxValue }, (_, i) => 1),
        backgroundColor: Array.from({ length: maxValue }, (_, i) => {
          if (i < riskValue) {
            if (riskValue <= 4) {
              return Colors.Success_500;
            } else if (riskValue <= 8) {
              return Colors.Warning_500;
            } else if (riskValue <= 10) {
              return Colors.Error_500;
            }
          } else {
            return "#e0e0e0";
          }
        }),
        borderWidth: 0.5,
      },
    ],
  };

  const options = {
    circumference: 250,
    rotation: -124, // Esto asegura que comience desde la parte superior central
    cutout: "93%", // Ajusta el corte interno del gráfico
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        display: false,
      },
    },
  };

  let plugins = [];

  if (issXs) {
    plugins = [
      {
        id: "numbers",
        afterDraw: (chart) => {
          const {
            ctx,
            chartArea: { top, bottom, left, right, width, height },
          } = chart;

          const centerX = (left + right) / 2;
          const centerY = (top + bottom) / 1.76; // Ajusta la posición Y para estar más centrado en la circunferencia
          const radius = Math.min(width, height) / 2.7; // Calcula el radio dinámicamente
          const stepAngle = (250 * Math.PI) / 180 / maxValue; // Ajusta según el ángulo de la circunferencia

          ctx.save();
          ctx.font = ` ${TextVariables.Caption_1.fontSize} ${TextVariables.Caption_1.fontFamily} ${TextVariables.Caption_1.fontWeight}`;
          ctx.fillStyle = Colors.Secondary_300;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";

          for (let i = 0; i < maxValue; i++) {
            const angle =
              stepAngle * i + (-124 * Math.PI) / 180 - stepAngle * 3.1;
            const x = centerX + (radius + 20) * Math.cos(angle); // Usa el radio dinámico
            const y = centerY + (radius + 20) * Math.sin(angle); // Usa el radio dinámico
            ctx.fillText((i + 1).toString(), x, y);
          }

          ctx.restore();
        },
      },
    ];
  } else {
    plugins = [
      {
        id: "numbers",
        afterDraw: (chart) => {
          const {
            ctx,
            chartArea: { top, bottom, left, right, width, height },
          } = chart;

          const centerX = (left + right) / 2;
          const centerY = (top + bottom) / 1.6; // Ajusta la posición Y para estar más centrado en la circunferencia
          const radius = Math.min(width, height) / 2.2; // Calcula el radio dinámicamente
          const stepAngle = (250 * Math.PI) / 180 / maxValue; // Ajusta según el ángulo de la circunferencia

          ctx.save();
          ctx.font = ` ${TextVariables.Caption_1.fontSize} ${TextVariables.Caption_1.fontFamily} ${TextVariables.Caption_1.fontWeight}`;
          ctx.fillStyle = Colors.Secondary_300;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";

          for (let i = 0; i < maxValue; i++) {
            const angle =
              stepAngle * i + (-124 * Math.PI) / 180 - stepAngle * 3.1;
            const x = centerX + (radius + 20) * Math.cos(angle); // Usa el radio dinámico
            const y = centerY + (radius + 20) * Math.sin(angle); // Usa el radio dinámico
            ctx.fillText((i + 1).toString(), x, y);
          }

          ctx.restore();
        },
      },
    ];
  }
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
        position: "relative",
        width: "100%",
        height: "100%",
        gap: "20px",
      }}
    >
      <Doughnut data={data} options={options} plugins={plugins} />
      <Box
        sx={{
          padding: "97px 0px 100px 0px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          width: "100%",
        }}
      >
        <Typography sx={{ fontFamily: TextVariables.H3 }}>
          {riskValue}
        </Typography>
        <Typography
          sx={{ fontFamily: TextVariables.Body_4, color: Colors.Secondary_900 }}
        >
          {t("Risk score")}
        </Typography>
      </Box>
      <Box sx={styles.settingsValuesBox}>
        <Box sx={styles.strategySettingsLabels}>
          <Typography sx={styles.valueSettingTitle}>1-4:</Typography>
          <Typography sx={styles.valueSettingTitle}>Healthy risk</Typography>
        </Box>
        {!issXs && <Typography sx={styles.separatorPoint}>&#8226;</Typography>}

        <Box sx={styles.strategySettingsLabels}>
          <Typography sx={styles.valueSettingTitle}>5-8:</Typography>
          <Typography sx={styles.valueSettingTitle}>Moderate risk</Typography>
        </Box>
        {!issXs && <Typography sx={styles.separatorPoint}>&#8226;</Typography>}
        <Box sx={styles.strategySettingsLabels}>
          <Typography sx={styles.valueSettingTitle}>9-10:</Typography>
          <Typography sx={styles.valueSettingTitle}>
            {t("High Risk (Be cautious)")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default HalfGaugeChart;
