//Follower settings component render when the user click the setting button in sidebarfollower component
import React, { useState, useEffect, } from "react";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import { Spaces  } from "../../../assets/Variables/SpaceVariables.js";
import { Box, Typography, Avatar, Button, Badge } from "@mui/material";
import TextIconButton from "../../Generals/Buttons/TextIconButton.js";
import TitleBigInput from "../../Generals/Inputs/TitledBigInput";
import Button3px from "../../Generals/Buttons/3pxButton";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import ShareLinkModal from "../../Generals/Notifications/ShareLinkModal.js";
import profileImage from "../../../assets/SignalTestImage/profileImage.png";
import { useDynamicStyles } from "./Styles";
import signalService from "../../../services/signalProvider";

function SocialHub(props) {
  //#region "Instantiation of States"
  const styles = useDynamicStyles();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [strategyInformation, setStrategyInformation] = useState(false);
  const [strategyImage, setStrategyImage] = useState(profileImage);
  const [newMessage, setNewMessage] = useState(false);
  const [newMessageText, setNewMessageText] = useState("");
  const [newMessageError, setNewMessageError] = useState(false);
  const [messages, setMessages] = useState([]);

  function formatDateTime(dateString, format) {
      const [datePart, timePart] = dateString.split(' ');
      const [year, month, day] = datePart.split('.');
      const [hours, minutes] = timePart.split(':');    
      const date = new Date(year, month - 1, day, hours, minutes);
    
      if (format === "hour") {
        let hour = date.getHours();
        const ampm = hour >= 12 ? 'pm' : 'am';
        hour = hour % 12 || 12;
        const minute = date.getMinutes().toString().padStart(2, '0');
        return `${hour}:${minute} ${ampm}`;
      }
      if (format === "date") {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
      }
    
      return "Invalid format";
  }
  //#endregion

  //#region "Handlers"
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleCopy = () => {

  };
  const handleOnClick = () => {
    setNewMessage(true);
  };

  const handleOnPost = async () => {
    if (newMessageText === "") {
      setNewMessageError(true);
    } else {
      setNewMessageError(false);
      setNewMessage(false);
      try {
        setLoading(true);
        const response = await signalService.newMessage({
          message: newMessageText
        });
        if (response.data != null) {
          if(props.signalInformation.signal_login){
            RequestChatMessages(props.signalInformation.signal_login);
          }
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false); // Desactiva el spinner una vez la operación finaliza
    }
    setNewMessageText("");
  };

  const handleOnCancel = () => {
    setNewMessageText("");
    setNewMessageError(false);
    setNewMessage(false);
  };

  const handleOnNewMessage = (event) => {
    setNewMessageText(event.target.value);
  };

  const renderComponent = () => {
    if (newMessage) {
      return (
        <Box sx={styles.socialEmptyContainer}>
          <Box sx={styles.socialSubEmptyContainer}>
            <Box sx={styles.newMessageTitle}>
              <Box sx={styles.socialTitle}>
                <span
                  className="material-symbols-outlined"
                  style={{ fontSize: Spaces.Size_10, lineHeight: 1 }}
                >
                  forum
                </span>
                <Typography
                  sx={{
                    fontFamily: TextVariables.Body_3_Bold,
                    lineHeight: 1,
                    textAlign: "center",
                  }}
                >
                  What do you want to share with your potential followers?
                </Typography>
              </Box>
              <Box sx={styles.newMessageBox}>
                <TitleBigInput
                  name={"newMessageInput"}
                  title={"Write your message"}
                  value={newMessageText}
                  handleChange={handleOnNewMessage}
                  maxChars={280}
                  placeholder={"Write your message"}
                  error={newMessageError}
                  helperText={
                    newMessageError ? (
                      <Box sx={styles.emptyFieldLabel}>
                        <span
                          className="material-symbols-outlined"
                          style={{ fontSize: Spaces.Size_3, color: "red" }}
                        >
                          error
                        </span>
                        <Typography
                          sx={{
                            fontFamily: TextVariables.Caption_1,
                            color: Colors.Error_700,
                          }}
                        >
                          Please, Enter a message
                        </Typography>
                      </Box>
                    ) : (
                      ""
                    )
                  }
                ></TitleBigInput>
              </Box>
            </Box>
            <Box sx={styles.newMessageButtonsBox}>
              <Button3px
                label={"Cancel"}
                color={Colors.Secondary_900}
                border={`1px solid ${Colors.Secondary_900}`}
                HandleOnClick={handleOnCancel}
              ></Button3px>
              <Button3px
                label={"Post"}
                color={"#fff"}
                backgroundColor={Colors.Error_700}
                border={`1px solid #fff`}
                HandleOnClick={handleOnPost}
              ></Button3px>
            </Box>
          </Box>
        </Box>
      );
    } else {
      if (messages.length === 0) {
        return (
          <Box sx={styles.socialEmptyContainer}>
            {props.rol === "follower" ?
              <Box sx={styles.socialSubEmptyContainer}>
                <Box sx={styles.socialTitle}>
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: Spaces.Size_10, lineHeight: 1 }}
                  >
                    forum
                  </span>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Body_3_Bold,
                      lineHeight: 1,
                    }}
                  >
                    Social Hub
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Body_4,
                      textAlign: "center",
                      width: "288px",
                    }}
                  >
                    At the moment the signal provider has not published any message. 
                    When it does, we will show it to you from here
                  </Typography>
                </Box>
              </Box> 
              : 
              <Box sx={styles.socialSubEmptyContainer}>
                <Box sx={styles.socialTitle}>
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: Spaces.Size_10, lineHeight: 1 }}
                  >
                    forum
                  </span>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Body_3_Bold,
                      lineHeight: 1,
                    }}
                  >
                    Social Hub
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: TextVariables.Body_4,
                      textAlign: "center",
                      width: "288px",
                    }}
                  >
                    Write notes to potential followers. Keep them updated with
                    news, share comments or invite them to follow your strategy!
                  </Typography>
                </Box>
                <TextIconButton
                  label={"Write a note"}
                  logo="add"
                  HandleOnClick={handleOnClick}
                ></TextIconButton>
              </Box> 
            }
          </Box>
        );
      } else if (messages.length > 0 && strategyInformation !== false) {
        return (
          <Box sx={styles.messageListMainBox}>
            <Box
              sx={
                props.mode === "signal"
                  ? styles.messageListBox
                  : styles.messageListBox2
              }
            >
              {messages.map((item, index) => (
                <Box key={index} sx={styles.mainMessageBox}>
                  <Box  sx={styles.messagesBox}>
                    <Avatar
                      alt="strategy image"
                      src={strategyImage}
                      sx={{ width: Spaces.Size_11, height: Spaces.Size_11 }}
                    ></Avatar>
                    <Box sx={styles.contentMessageBox}>
                      <Box sx={styles.messageTitleBox}>
                        <Typography
                          sx={{
                            fontFamily: TextVariables.Caption_1_Bold,
                            lineHeight: 1.2,
                          }}
                        >
                          {strategyInformation.strategy_name}
                        </Typography>
                        <Box sx={styles.messageDateBox}>
                          <Typography sx={styles.labelDateTitle}>
                            {formatDateTime(item.dateTime, "hour")}
                          </Typography>
                          <Typography sx={styles.separatorPoint}>
                            &#8226;
                          </Typography>
                          <Typography sx={styles.labelDateTitle}>
                            {formatDateTime(item.dateTime, "date")}
                          </Typography>
                        </Box>
                      </Box>

                      <Typography
                        sx={{
                          fontFamily: TextVariables.Caption_2,
                          lineHeight: "1.4",
                        }}
                      >
                        {item.message}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={styles.messageButtonsBox}>
                    <Button
                      onClick={handleOpenModal}
                      sx={{
                        display: "inline-block",
                        color: "black",
                        padding: "0px",
                        minWidth: "auto",
                        lineHeight: "1",
                        height: Spaces.Size_4,
                      }}
                    >
                    </Button>
                  </Box>
                </Box>
              ))}
            </Box>
            {props.mode === "signal" && (
              <Box sx={styles.addMessageButtonBox}>
                <TextIconButton
                  label={"Write a note"}
                  logo="add"
                  HandleOnClick={handleOnClick}
                ></TextIconButton>
              </Box>
            )}
          </Box>
        );
      } else {
        return <></>;
      }
    }
  };
  //#endregion

  //#region "Requests"
  const RequestChatMessages = async (signalAcc) => {
    try {
      setLoading(true);
      const response = await signalService.getChats(signalAcc);
      if (response.data != null) {
        setLoading(false);
        setMessages(response.data.data.data);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  };
  //#endregion
  
  //#region "useEffects"
  useEffect(() => {
    if(props.signalInformation){
      setStrategyInformation(props.signalInformation);
      if(props.signalInformation.signal_login){
        RequestChatMessages(props.signalInformation.signal_login);
      }
    }
  }, [props.signalInformation]);

  useEffect(() => {
    if(props.signalImage){
      setStrategyImage(props.signalImage);
    }
  }, [props.signalImage]);

  useEffect(() => {
    if(props.signalInformation){
      setStrategyInformation(props.signalInformation);
    }
  }, []);
  //#endregion

  //#region "HTML"
  return (
    <Box sx={styles.socialContainer}>
      <ShareLinkModal
        open={openModal}
        onClose={handleCloseModal}
        handleCopy={handleCopy}
        title="Share this signal provider's profile"
      ></ShareLinkModal>
      {renderComponent()}
    </Box>
  );
  //#endregion
}
export default SocialHub;
