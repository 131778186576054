import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.scss";
import Routes from "./App";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import globalEs from "./translations/es/global.json";
import globalEn from "./translations/en/global.json";
import globalCn from "./translations/cn/global.json";
import globalId from "./translations/id/global.json";
import globalLa from "./translations/la/global.json";
import globalMy from "./translations/my/global.json";
import globalRu from "./translations/ru/global.json";
import globalTh from "./translations/th/global.json";
import globalVn from "./translations/vn/global.json";
import "bootstrap/dist/css/bootstrap.min.css";

//const cookies = new Cookies();
//const lang = cookies.get("lang");
//const langSelected = lang !== null ? lang : "en";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    es: {
      global: globalEs,
    },
    en: {
      global: globalEn,
    },
    cn: {
      global: globalCn,
    },
    id: {
      global: globalId,
    },
    la: {
      global: globalLa,
    },
    my: {
      global: globalMy,
    },
    ru: {
      global: globalRu,
    },
    th: {
      global: globalTh,
    },
    vn: {
      global: globalVn,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <I18nextProvider i18n={i18next}>
    <Routes />

  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
