//Follower settings component render when the user click the setting button in sidebarfollower component
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { baseEnv } from "../../../App";
import { mainBox } from "../../../assets/Variables/SpaceVariables";
import "../../../css/follower/orders.scss";
import SignalSettings from "../../../services/signalProvider";
import { useTranslation } from "react-i18next";
import TableToolTip from "../../Generals/Tooltips/TableToolTip.js";
import followerService from "../../../services/follower";
import {
  Box,
  Paper,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import TableComponent from "../../Generals/Tables/TableComponent";
import LoadingSpinner from "../../Generals/Loaders/SpinLoader";
import Switch from "../../Generals/Buttons/Switch";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import * as spaceVariables from "../../../assets/Variables/SpaceVariables";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import SearchIcon from "@mui/icons-material/Search";
import DateSelector from "../../Generals/Selectors/DateSelector";
import IconComponent from "../../Generals/Icons/IconComponent.js";
import { getLocalTimeZoneOffsetInSeconds } from "../../../Tools/DateTimeConvertions";
import dayjs from "dayjs";
import Button3px from "../../Generals/Buttons/3pxButton";
import { useBreakpoint } from "../../../config/BreakpointContext.js";
import { useDynamicStyles } from "./Styles";

function FollowOrders(props) {
  //This function gets props from sidebarfollower component

  const navigate = useNavigate();
  //States
  const { isXs, isMd } = useBreakpoint();
  const styles = useDynamicStyles();
  const [openOrdersData, setOpenOrdersData] = useState("");
  const [closedOrdersData, setClosedOrdersData] = useState("");
  const [totalAmount, SetTotalAmount] = useState();
  const [pageOpen, setPageOpen] = useState(0);
  const [pageClose, setPageClose] = useState(0);
  const [pageLogs, setPageLogs] = useState(0);
  const [totalOrdersRows, setTotalOrdersRows] = useState(0);
  const [quantityPerPage, setQuantityPerPage] = useState(10);
  const [t] = useTranslation("global");
  const [pageCount, setPageCount] = useState(0); // Estado para el número de páginas
  const [rows, setRows] = useState([]); // Estado para los datos de las filas
  const [loading, setLoading] = useState([false]);
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [modalProps, setModalProps] = useState({
    open: false,
    iconType: "",
    title: "",
    message: "",
    onAcceptLabel: undefined,
    onRefuseLabel: undefined,
    onContinueLabel: undefined,
    onAccept: undefined,
    onRefuse: undefined,
    onContinue: undefined,
  });
  // Constants and Variables
  const [selectedSwitch, setSelectedSwitch] = useState("openTrades");
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const columnsOpen = [
    {
      field: "ticket",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Ticket")}</>,
    },
    {
      field: "open_time",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Open Time")}</>,
    },
    {
      field: "type",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Type")}</>,
    },
    {
      field: "symbol",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Symbol")}</>,
    },
    {
      field: "volume",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Volume")}</>,
    },
    {
      field: "open_price",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Price")}</>,
    },
    {
      field: "sl",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Stop Loss")}</>,
    },
    {
      field: "tp",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Take Profit")}</>,
    },
    {
      field: "commission",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Commission")}</>,
    },
    {
      field: "swap",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Swap")}</>,
    },
    {
      field: "pnl",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Profit/Loss")}</>,
    },
    {
      field: "comment",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Comment")}</>,
    },
  ];

  const columnsClosed = [
    {
      field: "ticket",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Ticket")}</>,
    },
    {
      field: "copy_ticket",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Copy Ticket")}</>,
    },
    {
      field: "open_time",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Open Time")}</>,
    },
    {
      field: "type",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Type")}</>,
    },
    {
      field: "symbol",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Symbol")}</>,
    },
    {
      field: "volume",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Volume")}</>,
    },
    {
      field: "open_price",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Price")}</>,
    },
    {
      field: "sl",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Stop Loss")}</>,
    },
    {
      field: "tp",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Take Profit")}</>,
    },
    {
      field: "close_time",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Close Time")}</>,
    },
    {
      field: "close_price",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Close Price")}</>,
    },
    {
      field: "commission",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Commission")}</>,
    },
    {
      field: "swap",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Swap")}</>,
    },
    {
      field: "pnl",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Profit/Loss")}</>,
    },
    {
      field: "comment",
      headerClassName: "header_table",
      renderHeader: () => <>{t("Comment")}</>,
    },
  ];

  const columnsLogs = [
    {
      field: "time",
      headerClassName: "header_table",
      renderHeader: () => <>{"Time"}</>,
    },
    {
      field: "strategy_followed",
      headerClassName: "header_table",
      renderHeader: () => <>{"Strategy name"}</>,
    },
    {
      field: "copy_mode",
      headerClassName: "header_table",
      renderHeader: () => <>{"Copy mode"}</>,
    },
    {
      field: "risk_ratio",
      headerClassName: "header_table",
      renderHeader: () => <>{"Risk ratio"}</>,
    },
    {
      field: "drawdown_mode",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="Type of drawdown protection">
          <Box sx={styles.tooltipBox}>
            {t("signalProvider.drawdown_mode")}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
    {
      field: "max_drawdown",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="The max value of drawdown to reach the limit">
          <Box sx={styles.tooltipBox}>
            {"Max. drawdown"}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
    {
      field: "min_lot_size",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="Defines whether the account will be disabled if the maximum drawdown value is reached">
          <Box sx={styles.tooltipBox}>
            {"Min. lot size"}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
    {
      field: "max_lot_size",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="Defines whether the account will be disabled if the maximum drawdown value is reached">
          <Box sx={styles.tooltipBox}>
            {"Max. lot size"}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
    {
      field: "connect",
      headerClassName: "header_table",
      renderHeader: () => (
        <TableToolTip title="Defines whether the strategy is visible to followers">
          <Box sx={styles.tooltipBox}>
            {"Copy status"}
            <IconComponent icon="help" size="12px" />
          </Box>
        </TableToolTip>
      ),
    },
    {
      field: "ip",
      headerClassName: "header_table",
      renderHeader: () => <>{"Ip"}</>,
    },
    {
      field: "message",
      headerClassName: "header_table",
      renderHeader: () => <>{"Message"}</>,
    },
  ];

  useEffect(() => {
    setFromDate(dayjs().startOf("day"));
    setToDate(dayjs().add(1, "day").startOf("day"));
    GetFollowerOpenOrders(quantityPerPage, pageOpen, search);
  }, []);

  const TriggerNavbar = (value) => {
    setSelectedSwitch(value);
    setLoading(true); // Mostrar el spinner mientras se cargan los datos
    setSearch("")
    if (value === "openTrades") {
      GetFollowerOpenOrders(quantityPerPage, pageOpen, search);
      setSelectedSwitch("openTrades");
    } else if (value === "closedTrades") {
      GetFollowerClosedOrders(
        quantityPerPage,
        pageClose,
        search,
        fromDate,
        toDate
      );
      setSelectedSwitch("closedTrades");
    } else {
      RequestSettingsLogs(quantityPerPage, pageLogs);
      setSelectedSwitch("historicalSettings");
    }
  };

  const GetFollowerOpenOrders = async (quantityPerPage, page, search) => {
    try {
      setLoading(true);
      const response = await SignalSettings.LoadSignalOpenOrders(
        quantityPerPage,
        page,
        search
      );

      if (response.status === 200) {
        setLoading(false); //Stops loading spinning
        setOpenOrdersData(response.data.data.data); //If response is correct, sets ip into the hook ip.
        setTotalOrdersRows(response.data.data.total_registers); //If response is correct, sets ip into the hook ip.
        let actualRows = response.data.data.data.map((log, index) => {
          const dateOpen = new Date(log.open_time * 1000); // Convertimos segundos a milisegundos
          const year = dateOpen.getUTCFullYear();
          const month = String(dateOpen.getUTCMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11
          const day = String(dateOpen.getUTCDate()).padStart(2, "0");
          const hours = String(dateOpen.getUTCHours()).padStart(2, "0");
          const minutes = String(dateOpen.getUTCMinutes()).padStart(2, "0");
          const secondsFormatted = String(dateOpen.getUTCSeconds()).padStart(
            2,
            "0"
          );

          const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${secondsFormatted}`;

          let row = {
            id: index,
            ticket: log.ticket,
            open_time: formattedDate,
            type: log.type,
            symbol: log.symbol,
            volume: log.volume,
            open_price: log.open_price,
            sl: log.sl,
            tp: log.tp,
            commission: log.commission,
            swap: log.swap,
            pnl: log.pnl,
            comment: log.comment,
          };
          return row;
        });
        setRows(actualRows);
        setPageCount(response.data.data.total_pages);
        SetTotalAmount(response.data.data.total_registers);
      }
      return response.data;
    } catch (error) {
      if (error.message === "Login is required") {
        console.log(error);
        setModalProps({
          open: true,
          iconType: "error",
          title: "Session Expired",
          message:
            "Your session has expired, please log in again and try again.",
          onAcceptLabel: "Continue",
          onAccept: () => handleCloseNotification(),
        });
        setLoading(false); //Stops loading spinning
        navigate(baseEnv + "/");
      } else {
        setLoading(false); //Stops loading spinning
        setModalProps({
          open: true,
          iconType: "error",
          title: "Server error",
          message:
            "An unexpected error has occurred, please contact an administrator",
          onAcceptLabel: "Continue",
          onAccept: () => handleCloseNotification(),
        });
      }
    }
  };

  const RequestSettingsLogs = async (quantity, page) => {
    try {
      setLoading(true);
      const response = await followerService.followerHistoricalSettings(
        quantity,
        page
      );
      if (response.data != null) {
        setLoading(false);
        let actualRows = response.data.data.data.map((log, index) => {
          let row = {
            id: index,
            strategy_followed: log.strategy_followed,
            copy_mode: log.copy_mode,
            risk_ratio: log.risk_ratio,
            drawdown_mode: log.drawdown_mode,
            max_drawdown: log.max_drawdown,
            min_lot_size: log.min_lot_size,
            max_lot_size: log.max_lot_size,
            time: log.time,
            connect: log.connect,
            ip: log.ip,
            message: log.message,
          };
          return row;
        });
        setRows(actualRows);
        setPageCount(response.data.data.total_pages);
        SetTotalAmount(response.data.data.total_registers);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setModalProps({
        open: true,
        iconType: "error",
        title: "Server error",
        message:
          "An error has occurred while loading the information. Please try reloading, if the problem persists please contact an administrator",
        onAcceptLabel: "Continue",
        onAccept: () => handleCloseNotification(),
      });
    }
    setLoading(false);
  };

  const handleCloseNotification = (prevModalProps) => {
    setModalProps({ ...prevModalProps, open: false });
  };

  const GetFollowerClosedOrders = async (
    quantityPerPage,
    page,
    search,
    from,
    to
  ) => {
    try {
      setLoading(true);
      const dateFromSeconds = from / 1000 + getLocalTimeZoneOffsetInSeconds();
      const dateToSeconds =
        to / 1000 + getLocalTimeZoneOffsetInSeconds() + 86400;
      const response = await SignalSettings.LoadSignalClosedOrders(
        quantityPerPage,
        page,
        search,
        dateFromSeconds,
        dateToSeconds
      );

      if (response.status === 200) {
        setLoading(false); //Stops loading spinning
        setClosedOrdersData(response.data.data.data); //If response is correct, sets ip into the hook ip.
        setTotalOrdersRows(response.data.data.total_registers); //If response is correct, sets ip into the hook ip.
        let actualRows = response.data.data.data.map((log, index) => {
          const dateOpen = new Date(log.open_time * 1000); // Convertimos segundos a milisegundos
          const yearOpen = dateOpen.getUTCFullYear();
          const monthOpen = String(dateOpen.getUTCMonth() + 1).padStart(2, "0"); // Los meses van de 0 a 11
          const dayOpen = String(dateOpen.getUTCDate()).padStart(2, "0");
          const hoursOpen = String(dateOpen.getUTCHours()).padStart(2, "0");
          const minutesOpen = String(dateOpen.getUTCMinutes()).padStart(2, "0");
          const secondsFormattedOpen = String(
            dateOpen.getUTCSeconds()
          ).padStart(2, "0");
          const formattedDateOpen = `${yearOpen}-${monthOpen}-${dayOpen} ${hoursOpen}:${minutesOpen}:${secondsFormattedOpen}`;

          const dateClose = new Date(log.close_time * 1000); // Convertimos segundos a milisegundos
          const yearClose = dateClose.getUTCFullYear();
          const monthClose = String(dateClose.getUTCMonth() + 1).padStart(
            2,
            "0"
          ); // Los meses van de 0 a 11
          const dayClose = String(dateClose.getUTCDate()).padStart(2, "0");
          const hoursClose = String(dateClose.getUTCHours()).padStart(2, "0");
          const minutesClose = String(dateClose.getUTCMinutes()).padStart(
            2,
            "0"
          );
          const secondsFormattedClose = String(
            dateClose.getUTCSeconds()
          ).padStart(2, "0");
          const formattedDateClose = `${yearClose}-${monthClose}-${dayClose} ${hoursClose}:${minutesClose}:${secondsFormattedClose}`;

          let row = {
            id: index,
            ticket: log.ticket,
            copy_ticket: log.copy_ticket,
            open_time: formattedDateOpen,
            type: log.type,
            symbol: log.symbol,
            volume: log.volume,
            open_price: log.open_price,
            sl: log.sl,
            tp: log.tp,
            close_time: formattedDateClose,
            close_price: log.close_price,
            commission: log.commission,
            swap: log.swap,
            pnl: log.pnl,
            comment: log.comment,
          };
          return row;
        });
        setRows(actualRows);
        setPageCount(response.data.data.total_pages);
        SetTotalAmount(response.data.data.total_registers);
      } else {
        setLoading(false); //Stops loading spinning
      }
      return response.data;
    } catch (error) {
      if (error.response.data.message === "Login is required") {
        setLoading(false); //Stops loading spinning
        setModalProps({
          open: true,
          iconType: "error",
          title: "Session Expired",
          message:
            "Your session has expired, please log in again and try again.",
          onAcceptLabel: "Continue",
          onAccept: () => handleCloseNotification(),
        });
        navigate(baseEnv + "/");
      } else {
        setLoading(false); //Stops loading spinning
        setClosedOrdersData("");
        setModalProps({
          open: true,
          iconType: "error",
          title: "Server error",
          message:
            "An unexpected error has occurred, please contact an administrator",
          onAcceptLabel: "Continue",
          onAccept: () => handleCloseNotification(),
        });
        //props.AlertMessage("signalProvider.Server error, try again", "error", "red"); //Specific error message, validates if it's a periodic request
      }
    }
  };

  const handlePageChange = (page) => {
    if (selectedSwitch === "openTrades") {
      setPageOpen(page);
      GetFollowerOpenOrders(quantityPerPage, page, search);
    } else if (selectedSwitch === "closedTrades") {
      setPageClose(page);
      GetFollowerClosedOrders(quantityPerPage, page, search, fromDate, toDate);
    } else {
      setPageLogs(page);
      RequestSettingsLogs(quantityPerPage, page);
    }
  };
  const handleFromDate = (date) => {
    setFromDate(date);
  };

  const handleToDate = (date) => {
    setToDate(date);
  };

  const handleSearch = () => {
    setPageOpen(0);
    setPageClose(0);
    if (selectedSwitch === "openTrades") {
      GetFollowerOpenOrders(10, 0, search);
    } else if ("closedTrades") {
      GetFollowerClosedOrders(10, 0, search, fromDate, toDate);
    }
  };

  return (
    <Box sx={mainBox}>
      {loading ? <LoadingSpinner /> : <></>}
      <Paper elevation={2} sx={styles.mainPaper}>
        <Typography sx={{ fontFamily: TextVariables.Body_3_Bold }}>
          {t("follower.Orders")}
        </Typography>
        {/* Box Padre de los botones de open y close */}
        <Box
          sx={{
            display: "flex",
            height: spaceVariables.Spaces.Size_13,
            padding: "0px 36px",
            justifyContent: "center",
            alignItems: "center",
            gap: spaceVariables.Spaces.Size_13,
            alignSelf: "stretch",
            borderRadius: spaceVariables.Spaces.Size_3,
            border: `1px solid ${Colors.Secondary_50}`,
          }}
        >
          <Switch
            label={t("follower.Open orders")}
            name="openTrades"
            selector={selectedSwitch}
            HandleOnClick={(e) => TriggerNavbar("openTrades")}
          />
          <Switch
            label={t("follower.Close orders")}
            name="closedTrades"
            selector={selectedSwitch}
            HandleOnClick={(e) => TriggerNavbar("closedTrades")}
          />
          <Switch
            label={"Historical settings"}
            name="historicalSettings"
            selector={selectedSwitch}
            HandleOnClick={(e) => TriggerNavbar("historicalSettings")}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMd ? "row" : "column",
            alignItems: isMd ? "flex-end" : "flex-start",
            justifyContent: "flex-start",
            gap: spaceVariables.Spaces.Size_5,
          }}
        >
          {selectedSwitch === "closedTrades" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: spaceVariables.Spaces.Size_5,
              }}
            >
              <DateSelector
                title={t("follower.From")}
                value={fromDate}
                handleChange={handleFromDate}
              ></DateSelector>
              <DateSelector
                title={t("follower.To")}
                value={toDate}
                handleChange={handleToDate}
              ></DateSelector>
            </Box>
          )}
            {selectedSwitch === "historicalSettings" ?
              (null) :
                (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-end",
                      justifyContent: "flex-start",
                      gap: spaceVariables.Spaces.Size_5,
                      width: "100%",
                    }}
                  >
                    <TextField
                      value={search}
                      onChange={handleSearchChange}
                      placeholder={t("follower.Search order")}
                      sx={{
                        width: "263px",
                        maxHeight: "38px",
                        padding: "0px",
                        fontFamily: TextVariables.Overline,
                        border: `1px solid ${Colors.Secondary_400}`,
                        borderRadius: "8px",
                        "& .MuiOutlinedInput-root": {
                          "& .MuiInputBase-input": {
                            padding: spaceVariables.Spaces.Size_3,
                            fontFamily: TextVariables.Overline,
                            color: Colors.Secondary_900,
                          },
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              marginRight: "0px",
                            }}
                          >
                            <SearchIcon padding={0} />
                          </InputAdornment>
                        ),
                        sx: {
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none", // Elimina el borde
                          },
                        },
                      }}
                    />
                  <Button3px
                    label={"Search"}
                    color={Colors.Secondary_700}
                    backgroundColor={Colors.Gray_200}
                    HandleOnClick={handleSearch}
                  ></Button3px>
                </Box>
              </>)
            }
        </Box>
        <Box sx={{ width: "100%", marginTop: "30px", overflowX: 'auto'}}>
          <Box sx={{ minWidth: "1400px" }}>
            <TableComponent
              sx={{ width: "100%" }}
              rows={rows}
              page={
                selectedSwitch === "openTrades"
                  ? pageOpen
                  : selectedSwitch === "closedTrades"
                  ? pageClose
                  : pageLogs
              }
              totalAmount={totalAmount}
              pageCount={pageCount}
              // handleChangePage={handleChangePage}
              columns={
                selectedSwitch === "closedTrades"
                  ? columnsClosed
                  : selectedSwitch === "openTrades"
                  ? columnsOpen
                  : columnsLogs
              }
              handleChangePage={handlePageChange}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

export default FollowOrders;
