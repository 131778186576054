import { Button } from "@mui/material";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import {Spaces} from "../../../assets/Variables/SpaceVariables.js";

function RedButton({ children, HandleOnClick }) {
    return (
        <Button
            onClick={HandleOnClick}
            sx={{
                backgroundColor: "#FF0000",
                fontFamily: TextVariables.Body_4,
                color: "white",
                textTransform: "none",
                padding: `${Spaces.Size_4}`,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: Spaces.Size_2,
                lineHeight: "1",
                "&:hover": {
                backgroundColor: "white", // Cambia el color al hacer hover
                opacity: 0.8, // Aplica opacidad al hacer hover
                border: "2px solid #FF0000",
                color: "#FF0000"
                },
            }}
        >
        {children}
        </Button>
    );
}

export default RedButton;
