//Singleton script tha contains all the request uses in follower components
import axios from "axios";
import Cookies from "universal-cookie";

//Main path builds depending on the enviroment selected or run by administrator
const cookies = new Cookies();
const url = cookies.get("url");
const user = cookies.get("user");
let token = cookies.get("token");

let headers = {
  //Headers preparation, the token is get from cookies, the same token obtained when the user log in
  "Content-type": "application/json; charset=UTF-8",
  Authorization: "Bearer " + token,
};
let baseUrl = `${url}/api`;

function manejarCookieActualizada(event) {
  baseUrl = `${event.detail.value}/api`;
}

function onUpdateToken(event) {
  token = event.detail.value;
  headers.Authorization = "Bearer " + token;
}

// Agregar un listener para el evento de cookieActualizada
window.addEventListener("updatedUrl", manejarCookieActualizada);
window.addEventListener("tokenUpdatedEvent", onUpdateToken);

//Request that bring the list of Signal providers available
const getMasters = async (quantity, page) => {
  const res = await axios.get(
    `${baseUrl}/signal-list?quantity=${quantity}&page=${page}`,
    { headers }
  );
  return res;
};

//Request that bring the list of Signal providers available in the new Portal
const getSignals = async (quantity, page, search = "") => {
  const res = await axios.get(
    `${baseUrl}/signal-list?quantity=${quantity}&page=${page}&${search}`,
    { headers: headers }
  );
  return res;
};

//Request that starts to follow a Signal user
const follow = async (params) => {
  const res = await axios.post(`${baseUrl}/follow`, params, { headers });
  return res;
};

//Request that stops to follow a Signal user
const stopFollowing = async () => {
  const res = await axios.delete(`${baseUrl}/follow`, { headers });
  return res;
};

//It's not being used in this moment
const setGoodRate = async (credentials) => {
  const res = await axios.post(`${baseUrl}/login`, credentials);
  return res;
};

//It's not being used in this moment
const setBadRate = async (credentials) => {
  const res = await axios.post(`${baseUrl}/login`, credentials);
  return res;
};

//It's not being used in this moment
const connectMyAccount = async (credentials) => {
  const res = await axios.post(`${baseUrl}/login`, credentials);
  return res;
};

//It's not being used in this moment
const disconnectMyAccount = async (credentials) => {
  const res = await axios.post(`${baseUrl}/login`, credentials);
  return res;
};

//It's not being used in this moment
const disconnectMyAccountHidden = async (credentials) => {
  const res = await axios.post(`${baseUrl}/login`, credentials);
  return res;
};

//Request that bring the list of historical settings made for follower user
const followerHistoricalSettings = async (quantity, page) => {
  const res = await axios.get(
    `${baseUrl}/historical?quantity=${quantity}&page=${page}`,
    { headers }
  );
  return res;
};

//It's not being used in this moment
const followStatus = async (params) => {
  const res = await axios.post(`${baseUrl}/getSlaves`, params);
  return res;
};

//It's not being used in this moment
const copyStatus = async (params) => {
  const res = await axios.post(`${baseUrl}/getSlaves`, params);
  return res;
};

//Request that updates the information for a follower user
const editFollower = async (params) => {
  const res = await axios.put(`${baseUrl}/information/follower`, params, {
    headers,
  });
  return res;
};

//Request function to get the list of follower open orders from de communitraders server

const LoadFollowerOpenOrders = async (quantity, page) => {
  try {
    const res = await axios.get(
      `${baseUrl}/open_trades_history?quantity=${quantity}&page=${page}`,
      { headers: headers }
    );
    return res;
  } catch (error) {
    throw error; // Asegúrate de lanzar el error para manejarlo en el lugar donde se llama a la función
  }
};

//Request function to get the list of follower open orders from de communitraders server

const LoadFollowerClosedOrders = async (quantity, page, from, to) => {
  try {
    const res = await axios.get(
      `${baseUrl}/closed_trades_history?from=${from}&to=${to}&quantity=${quantity}&page=${page}&search=`,
      { headers: headers }
    );
    return res;
  } catch (error) {
    throw error; // Asegúrate de lanzar el error para manejarlo en el lugar donde se llama a la función
  }
};

const LoadSignalClosedOrders = async (
  quantity,
  page,
  search,
  from,
  to,
  signalLogin
) => {
  try {
    const res = await axios.get(
      `${baseUrl}/closed_trades_history?from=${from}&to=${to}&quantity=${quantity}&page=${page}&search=${search}&signal_login=${signalLogin}`,
      { headers: headers }
    );
    return res;
  } catch (error) {
    throw error; // Asegúrate de lanzar el error para manejarlo en el lugar donde se llama a la función
  }
};

const getAllSymbols = async (
) => {
  try {
    const res = await axios.get(
      `${baseUrl}/get-symbols`,
      { headers: headers }
    );
    return res;
  } catch (error) {
    throw error; // Asegúrate de lanzar el error para manejarlo en el lugar donde se llama a la función
  }
};

const methods = {
  getSignals,
  getMasters,
  follow,
  stopFollowing,
  setGoodRate,
  setBadRate,
  connectMyAccount,
  disconnectMyAccount,
  disconnectMyAccountHidden,
  followerHistoricalSettings,
  LoadFollowerOpenOrders,
  LoadFollowerClosedOrders,
  followStatus,
  copyStatus,
  editFollower,
  LoadSignalClosedOrders,
  getAllSymbols,
};

export default methods;
