import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import { useBreakpoint } from "../../../config/BreakpointContext.js";

export const useDynamicStyles = () => {
  const { isXs, isMd } = useBreakpoint();

  return {
    StrategyNameBoxStyle : {
      height: "250px",
      display: "flex",
      flexDirection: "row",
      width: "45%",
      margin: "10px 0px 0px 30px",
      alignItems: "center",
    },
    
    ShareBoxStyle : {
      margin: "10px 0px 0px 10px",
      width: "102px",
      height: "48px",
      textTransform: "none",
      fontFamily: TextVariables.Caption_bold_1,
    },
    
    form : {
      display: "flex",
      flexDirection: "column",
      width: isMd ? "calc(100vw - 280px)" : "100vw",
      padding: isMd ? `0 0 0 0` : `0 ${Spaces.Size_3} 0 ${Spaces.Size_3}`,
      maxWidth: "1200px",
      gap: Spaces.Size_8,
      height: "auto"
    },
    
    profileSocialContainer : {
      display: "flex",
      width: "100%",
      flexDirection: isMd ? "row": "column",
      gap: Spaces.Size_8,
      height: "100%"
    },
    
    profileContainer : {
      display: "flex",
      flexDirection: "column",
      align: "flex-start",
      backgroundColor:
        "linear-gradient(156deg, rgba(255, 255, 255, 0.50) 10.28%, rgba(255, 255, 255, 0.00) 106.66%)",
      width: isMd ? "60%" : "100%",
      padding: Spaces.Size_6,
      gap: Spaces.Size_6,
      borderRadius: Spaces.Size_5,
      border: "1px rgba(255, 255, 255, 0.42)",
      boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
      backdropFilter: "blur(20px)",
      height: "auto"
    },
    
    socialContainer : {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      backgroundColor:
        "linear-gradient(156deg, rgba(255, 255, 255, 0.50) 10.28%, rgba(255, 255, 255, 0.00) 106.66%)",
      padding: Spaces.Size_6,
      borderRadius: Spaces.Size_5,
      border: "1px rgba(255, 255, 255, 0.42)",
      boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
      backdropFilter: "blur(20px)",
      gap: Spaces.Size_7,
    },
    
    statisticalTradesContainer : {
      display: "flex",
      flexDirection: isMd ? "row" : "column",
      gap: Spaces.Size_8,
      height: isMd ? "auto" : "100%"
    },
    
    statisticalContainer : {
      height: isMd ? "532px" : "732px",
      width: isMd ? "70%" : "100%",
      display: "flex",
      flexDirection: "column",
      padding: Spaces.Size_6,
      alignItems: "flex-start",
      gap: Spaces.Size_7,
      borderRadius: Spaces.Size_5,
      border: `1px solid rgba(255, 18, 246, 0.06)`,
      background:
        "linear-gradient(156deg, rgba(255, 255, 255, 0.50) 10.28%, rgba(255, 255, 255, 0.00) 106.66%)",
      boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
      backdropFilter: "blur(20px)",
    },
    
    totalTradesContainer : {
      flex: isMd ? "1 0 0" : "",
      padding: `${Spaces.Size_6} 0`,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: Spaces.Size_7,
      borderRadius: Spaces.Size_5,
      border: `1px solid rgba(255, 18, 246, 0.06)`,
      background:
        "linear-gradient(156deg, rgba(255, 255, 255, 0.50) 10.28%, rgba(255, 255, 255, 0.00) 106.66%)",
      boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
      backdropFilter: "blur(20px)",
      height: isMd ? "auto" : "300px"
    },
    
    settingsFollowingContainer : {
      display: "flex",
      width: "100%",
      height: "100",
      flexDirection: "column",
      padding: Spaces.Size_6,
      gap: Spaces.Size_8,
      borderRadius: Spaces.Size_5,
      border: `1px solid rgba(255, 18, 246, 0.06)`,
      background:
        "linear-gradient(156deg, rgba(255, 255, 255, 0.50) 10.28%, rgba(255, 255, 255, 0.00) 106.66%)",
      boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
      backdropFilter: "blur(20px)",
    },
    
    settingsLogsContainer : {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      padding: Spaces.Size_6,
      gap: Spaces.Size_8,
      borderRadius: Spaces.Size_5,
      border: `1px solid rgba(255, 18, 246, 0.06)`,
      background:
        "linear-gradient(156deg, rgba(255, 255, 255, 0.50) 10.28%, rgba(255, 255, 255, 0.00) 106.66%)",
      boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
      backdropFilter: "blur(20px)",
    },
    
    socialNoChatsContainer : {
      height: "100%",
      margin: "0px 40px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: Spaces.Size_6,
    },
    
    chartContainer : {
      height: "100%",
      width: "100%",
      background: "grey",
    },
    
    totalTradesIndicator : {
      borderRadius: "5px",
      width: "124px",
      padding: Spaces.Size_1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      background: "rgba(217, 217, 217, 1)",
    },
    
    createStrategyBox : {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      gap: Spaces.Size_9,
    },
    
    strategySettingsTitleBox : {
      width: isMd ? "591px" : "100%",
      display: "flex",
      flexDirection: "column",
      gap: Spaces.Size_5,
    },
    
    settingFormContainer : {
      width: isMd ? "591px" : "100%",
      display: "flex",
      flexDirection: "column",
      gap: Spaces.Size_9,
      padding: Spaces.Size_6,
      background:
        "linear-gradient(156deg, rgba(255, 255, 255, 0.50) 10.28%, rgba(255, 255, 255, 0.00) 106.66%)",
      border: "1px solid rgba(255, 18, 246, 0.06)",
      borderRadius: Spaces.Size_5,
      boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.08)",
      backdropFilter: "blur(20px)",
    },
    
    
    generalInformationContainer : {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: Spaces.Size_6,
    },
    
    settingFormTitle : {
      width: "100%",
      display: "flex",
      flexDirection: isMd ? "row" : "column",
      gap: Spaces.Size_2,
      alignItems: isMd ? "center" : "flex-start",
    },
    
    circularNumber : {
      width: Spaces.Size_7,
      height: Spaces.Size_7,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid black",
      borderRadius: Spaces.Size_11,
      fontFamily: TextVariables.Caption_1_Bold,
    },
    
    strategyImageTitle : {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: Spaces.Size_1,
      fontFamily: TextVariables.Body_4,
      lineHeight: "1.4",
    },
    
    strategyPhotoContainer : {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: Spaces.Size_4,
    },
    
    uploadPhotoButton : {
      fontFamily: TextVariables.Caption_1_Bold,
      color: Colors.Secondary_900,
      textTransform: "none",
      padding: `${Spaces.Size_3}`,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: Spaces.Size_2,
      lineHeight: "1",
      border: `1px solid ${Colors.Secondary_900}`,
      "&:hover": {
        backgroundColor: Colors.Secondary_50, // Cambia el color al hacer hover
        opacity: 0.8, // Aplica opacidad al hacer hover
      },
    },
    
    settingsTitleBox : {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "2px"
    },
    
    settingsValuesBox : {
      display: "flex",
      flexDirection: "row",
      gap: Spaces.Size_2,
      alignItems: "center",
    },
    
    strategySettingsLabels : {
      display: "flex",
      flexDirection: "row",
      gap: "2px"
    },
    
    settingTitle : {
      fontFamily: isMd ? TextVariables.Caption_2 : TextVariables.Overline,
      color: Colors.Secondary_600,
      lineHeight: "1.4",
    },

    valueSettingTitle : {
      fontFamily: TextVariables.Caption_2_Bold,
      lineHeight: "1.4",
      color: Colors.Secondary_300,
    },
    
    labelDateTitle : {
      fontFamily: TextVariables.Overline,
      lineHeight: "1",
      color: Colors.Secondary_700,
    },
    
    separatorPoint : {
      fontFamily: TextVariables.Caption_2_Bold,
      lineHeight: "1.4",
      color: Colors.Secondary_400,
      display: "flex",
      alignItems: "center",
    },
    
    strategyTypeFeeBox : {
      display: "flex",
      flexDirection: "row",
      gap: Spaces.Size_6,
      width: "100%",
    },
    
    submitBox : {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "flex-end",
    },
    
    emptyFieldLabel : {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: Spaces.Size_1,
    },
    
    profileTitleBox : {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      gap: Spaces.Size_5,
      width: "100%",
    },
    
    avatarTitleBox : {
      display: "flex",
      flexDirection: isMd ? "row" : "column",
      alignItems: isMd ? "center" : "flex-start",
      gap: isMd ? Spaces.Size_5 : Spaces.Size_3,
    },
    
    shareLinkButton : {
      display: "flex",
      padding: Spaces.Size_3,
      justifyContent: "center",
      alignItems: "center",
      gap: Spaces.Size_2,
      backgroundColor: Colors.Primary_700,
      fontFamily: TextVariables.Body_3_Bold,
      color: "white",
      textTransform: "none",
      borderRadius: Spaces.Size_2,
      "&:hover": {
        backgroundColor: "#FF0000", // Cambia el color al hacer hover
        opacity: 0.8, // Aplica opacidad al hacer hover
      },
    },
    
    descriptionBox : {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: Spaces.Size_3,
    },
    
    strategyOverviewBox : {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: Spaces.Size_4,
      height: "auto"
    },
    
    StrategyOverviewTitleBox : {
      display: "flex",
      flexDirection: isMd ? "row" : "column",
      alignItems: isMd ? "center" : "flex-start",
      justifyContent: "center",
      gap: Spaces.Size_4,
    },
    
    chipsContainer : {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: Spaces.Size_2,
    },
    
    strategyCardsBox : {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between",
      gap: Spaces.Size_4,
    },
    
    shareLinkBox : {
      display: "flex",
      flexDirection: "column",
      padding: Spaces.Size_9,
      alignItems: "center",
      gap: Spaces.Size_6,
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "415px",
      borderRadius: Spaces.Size_4,
      background: "white",
      boxShadow: "0px 0px 16px 0px rgba(21, 32, 42, 0.10)",
    },
    
    socialSubEmptyContainer : {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: Spaces.Size_7,
      alignSelf: "stretch",
    },
    
    messageListBox : {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: Spaces.Size_4,
      height: "300px",
      overflowY: "scroll",
      "::-webkit-scrollbar": {
        width: "4px",
        height: "4px",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
        borderRadius: "10px",
        "&:hover": {
          backgroundColor: "#555",
        },
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
    },
    
    messageListBox2 : {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: Spaces.Size_4,
      height: "350px",
      overflowY: "scroll",
      "::-webkit-scrollbar": {
        width: "4px",
        height: "4px",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
        borderRadius: "10px",
        "&:hover": {
          backgroundColor: "#555",
        },
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
    },
    
    socialTitle : {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: Spaces.Size_4,
      flex: "1 0 0",
    },
    
    newMessageTitle : {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: Spaces.Size_7,
      flex: "1 0 0",
    },
    
    mainMessageBox : {
      display: "flex",
      flexDirection: "column",
      padding: Spaces.Size_3,
      alignItems: "flex-start",
      gap: Spaces.Size_1,
      borderRadius: Spaces.Size_3,
      border: `1px solid ${Colors.Secondary_50}`,
      background: "#fff",
      width: "calc(100% - 4px)",
      marginRight: Spaces.Size_1,
    },
    
    messagesBox : {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      gap: Spaces.Size_3,
      marginRight: Spaces.Size_1,
      borderBottom: `1px solid ${Colors.Secondary_50}`
    },
    
    messageButtonsBox : {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      width: "100%",
      gap: Spaces.Size_3,
    },
    
    socialEmptyContainer : {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      gap: Spaces.Size_3,
    },
    
    messageListMainBox : {
      display: "flex",
      width: "100%",
      height: "100%",
      flexDirection: "column",
      gap: Spaces.Size_3,
      justifyContent: "space-between",
    },
    
    messageTitleBox : {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    
    messageDateBox : {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: Spaces.Size_1,
    },
    
    contentMessageBox : {
      display: "flex",
      flexDirection: "column",
      gap: Spaces.Size_2,
    },
    
    addMessageButtonBox : {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    
    newMessageBox : {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
    
    newMessageButtonsBox : {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "flex-end",
      gap: Spaces.Size_5,
    },
    
    statisticalNavbarBox : {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: isMd ? Spaces.Size_13 : Spaces.Size_8,
      padding: isMd ? `0px ${Spaces.Size_9}` : `0`,
    },
    
    performanceBox : {
      display: "flex",
      width: "100%",
      flex: "1 0 0",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: Spaces.Size_5
    },
    
    statisticalLegendsBox : {
      width: "100%",
      display: "flex",
      flexDirection: isMd ? "row" : "column",
      justifyContent: "space-between",
      alignItems: "center",
      gap: isMd ? "0px" : Spaces.Size_3
    },
    
    statisticalFiltersBox : {
      display: "flex",
      flexDirection: isMd ? "row" : "column",
      gap: isMd ? Spaces.Size_4 : Spaces.Size_3,
      width: isMd ? "auto" : "100%" ,
      maxWidth: "288px"
    },

    statisticalFrecuencyContainer : {
      display: "flex",
      flexDirection: "row",
      gap: isMd ? Spaces.Size_4 : Spaces.Size_3,
      justifyContent: "center",
      width: isMd ? "auto" : "100%" ,
      maxWidth: "288px"
    },
    
    ChipHumanStyle : {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "400",
      color: Colors.Success_900,
      borderRadius: "8px",
      textTransform: "none",
      background: Colors.Success_50,
      height: "23px",
      width: "auto",
    },
    ChipSupervisedStyle : {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "400",
      color: Colors.Info_900,
      borderRadius: "8px",
      textTransform: "none",
      background: Colors.Info_200,
      height: "23px",
    },
    
    ChipRobotStyle : {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "400",
      color: Colors.Warning_900,
      borderRadius: "8px",
      textTransform: "none",
      background: Colors.Warning_100,
      height: "23px",
    },
    
    tooltipBox : {
      display: "flex",
      flexDirection: "row",
      gap: Spaces.Size_1,
      alignItems: "center",
      justifyContent: "center"
    },
    
    settingsFollowingBox : {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    
  };
};




