import "../../../css/follower/follower.scss";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../../css/signal/signalProfile.scss";
import { useTranslation } from "react-i18next";
import userService from "../../../services/user";
import LoadingSpinner from "../../Generals/Loaders/SpinLoader";
import * as styles from "../ProfileScreen/Styles.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import { mainBox } from "../../../assets/Variables/SpaceVariables.js";
import { Box, Typography, Paper } from "@mui/material";
import backgroundImage from "../../../assets/logos/noContentFollower.svg";
import GenericButton from "../../Generals/Buttons/3pxButton.js";
import FollowedSignalDashboard from "./FollowedSignalDashboard";
import NotificationModal from "../../Generals/Notifications/NotificationModal.js";

import {
  SetFollowingSettings,
  ClearFollowingSettings,
} from "../../../Tools/LocalStorage";

function FollowProfile(props) {
  //#region "Instantiation of States"
  const [loading, setLoading] = useState(false);
  const [followerInformation, setFollowerInformation] = useState("");
  const [modalProps, setModalProps] = useState({
    open: false,
    iconType: "",
    title: "",
    message: "",
    onAcceptLabel: undefined,
    onRefuseLabel: undefined,
    onAccept: undefined,
    onRefuse: undefined,
  });
const navigate = useNavigate();
//#endregion

//#region "Handlers"
const handleDiscover = () => {
  navigate("/Follower/Discover");
};
const handleCloseNotification = (prevModalProps) => {
  setModalProps({...prevModalProps, open: false});
};
//#endregion

//#region "Requests"
const RequestFollowerInfo = async () => {
  try {
    setLoading(true);
    const response = await userService.getInformation();
    if (response.data != null) {
      setLoading(false);
      setFollowerInformation(response.data.data);
      SetFollowingSettings(response);
    } else {
      setLoading(false);
    }
  } catch (error) {
    console.log(error);
    setLoading(false);
    setFollowerInformation("");
    ClearFollowingSettings();
    if(error.response.data.message !== "This account does not follow a signal"){
      setModalProps({
        open: true,
        iconType: "error",
        title: "Server error",
        message: "An unexpected error has occurred, please contact an administrator",
        onAcceptLabel: "Continue",
        onAccept: () => handleCloseNotification(),
      });
    }
  }
  setLoading(false);
};
//#endregion

//#region "useEffects"
useEffect(() => {
  RequestFollowerInfo();
}, []);
//#endregion

//#region "HTML"
return (
  <Box sx={mainBox}>
    <NotificationModal
      open={modalProps.open}
      onClose={handleCloseNotification}
      iconType={modalProps.iconType}
      title={modalProps.title}
      message={modalProps.message}
      onAcceptLabel={modalProps.onAcceptLabel}
      onAccept={modalProps.onAccept}
    />
    {loading ? <LoadingSpinner /> : <></>}
    {followerInformation !== "" && <FollowedSignalDashboard></FollowedSignalDashboard>}
    {followerInformation === "" && (
      <Paper sx={styles.form}>
        <Typography
          sx={{
            fontFamily: TextVariables.Body_3_Bold,
            position: "absolute",
            top: 20,
            left: 20,
          }}
        >
          Following details
        </Typography>
        <Box sx={styles.noContentBox}>
          <img
            alt="background empty"
            src={backgroundImage}
            style={{
              mixBlendMode: "luminosity",
              width: 350,
              opacity: 0.8,
              maxWidth: "40%",
            }}
          />
          <Box sx={styles.noContentBoxEmptyText}>
            <Typography sx={{ fontFamily: TextVariables.Body_3_Bold }}>
              You’re not following any strategy yet
            </Typography>
            <Typography sx={{ fontFamily: TextVariables.Caption_1 }}>
              Don’t miss out! Explore trading opportunities and consider them
              thoughtfully
            </Typography>
          </Box>
          <GenericButton
            HandleOnClick={handleDiscover}
            label={"Discover strategies"}
            color="white"
            backgroundColor={Colors.Error_500}
          ></GenericButton>
        </Box>
      </Paper>
    )}
  </Box>
);
//#endregion
}

export default FollowProfile;
