import { Box, Typography } from "@mui/material";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";

export function InfoSettingsBox({ title, detail, logo }) {
  return (
    <Box
      sx={{
        display: "flex",
        padding: Spaces.Size_4,
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: Spaces.Size_3,
        border: "1px solid rgba(255, 18, 246, 0.06)",
        background: "#fff"
      }}
    >
      <Box>
        <Typography sx={{ fontFamily: TextVariables.Caption_2 }}>
          {title}
        </Typography>
        <Typography sx={{ fontFamily: TextVariables.Body_4_Bold }}>
          {detail}
        </Typography>
      </Box>
    </Box>
  );
}

export default InfoSettingsBox;
