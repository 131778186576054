import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
} from "@mui/material";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import * as ColorsVariables from "../../../assets/Variables/ColorsVariables.js";
import AvatarImg1 from "../../../assets/SignalTestImage/profileImage.png";
import { useNavigate } from "react-router-dom";
import FilterComponent from "./FilterComponent";
import followerServices from "../../../services/follower";
import FollowingModal from "../../Generals/Modals/FollowingModal.js";
import * as masterData from "../../../Master Data/masterData.js";
import LoadingSpinner from "../../Generals/Loaders/SpinLoader";
import followerService from "../../../services/follower.js";
import NotificationModal from "../../Generals/Notifications/NotificationModal.js";
import {
  GetFollowingSettings,
  ClearFollowingSettings,
  SetFollowingSettings,
} from "../../../Tools/LocalStorage.js";
import * as Encryptor from "../../../Tools/AESEncrypter";
import MuiPagination from "@mui/material/Pagination";
import backgroundImage from "../../../assets/logos/noContentFollower.svg";
import Cookies from "universal-cookie";
import { useDynamicStyles } from "./Styles";
import { useBreakpoint } from "../../../config/BreakpointContext.js";
import StatusModule from "../../Generals/Cards/StatusModule.js";

function DiscoverScreen(props) {
  //#region "Instantiation of States"
  //States instances
  const { isXs, isMd } = useBreakpoint();
  const Styles = useDynamicStyles();
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [modalData, setModalData] = useState({});
  const [followingSettings, setFollowingSettings] = useState({});
  const [formValues, setFormValues] = useState({
    signal_followed: "0",
    copy_mode: "Proportional",
    risk_ratio: 1,
    drawdown_mode: "NoDrawdown",
    max_drawdown: 0,
    min_lot_size: 0,
    max_lot_size: 0,
    connect: true,
  });
  const [errors, setErrors] = useState({
    signal_followed: false,
    copy_mode: false,
    risk_ratio: false,
    drawdown_mode: false,
    max_drawdown: false,
    min_lot_size: false,
    max_lot_size: false,
    connect: true,
  });
  const [page, setPage] = useState(1); // Estado para la página actual
  const [pageCount, setPageCount] = useState(2); // Estado para el número de páginas
  const formValuesRef = useRef(formValues);
  // Estado para manejar la selección de assets y currencies
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [selectedCurrencies, setSelectedCurrencies] = useState([]);
  //Botones de orden
  const [sortOrder, setSortOrder] = useState("all");
  const [signalList, setSignalList] = useState([]);
  const [modalProps, setModalProps] = useState({
    open: false,
    iconType: "",
    title: "",
    message: "",
    onAcceptLabel: undefined,
    onRefuseLabel: undefined,
    onContinueLabel: undefined,
    onAccept: undefined,
    onRefuse: undefined,
    onContinue: undefined,
  });
  const cookies = new Cookies();
  const url = cookies.get("url");

  //#region "Handlers"
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleFilterChange = (filterName) => {
    switch (filterName) {
      case "Most popular":
        setSortOrder("most_popular");
        break;
      case "Low fees":
        setSortOrder("low_fees");
        break;
      case "High risk":
        setSortOrder("high_risk");
        break;
      case "Low risk":
        setSortOrder("low_risk");
        break;
      default:
        setSortOrder("all");
        break;
    }
  };

  // Funciones de manejo de cambio para assets y currencies
  const handleAssetChange = (event) => {
    if (!event || !event.target) {
      return;
    }

    const { value } = event.target;

    setSelectedAssets(typeof value === "string" ? value.split(",") : value);
  };

  const handleCurrencyChange = (event) => {
    if (!event || !event.target) {
      return;
    }

    const { value } = event.target;

    setSelectedCurrencies(typeof value === "string" ? value.split(",") : value);
  };
  

  const handleDiscoverMore = (signalLogin) => {
    if (signalLogin === followingSettings.signal_followed) {
      navigate(`/Follower/FollowingSettings`);
    } else {
      const encryptSignal = Encryptor.encrypt(signalLogin);
      const sureEncryptSignal = Encryptor.makeUrlSafe(encryptSignal);
      navigate(`/Follower/Discover/Strategy/${sureEncryptSignal}`);
    }
  };

  const handleSendCopyStatusEvent = (body) => {
    const event = new CustomEvent("copyStatus", {
      detail: { message: body },
    });

    // Emitir el evento
    window.dispatchEvent(event);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Actualiza los valores del formulario

    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const currentFormValues = formValuesRef.current;
    let valid = true;
    let newErrors = {};
    // Verificar cada campo
    for (const [key, value] of Object.entries(currentFormValues)) {
      if (
        key === "min_lot_size" &&
        currentFormValues.max_lot_size !== 0 &&
        parseInt(value) >= currentFormValues.max_lot_size
      ) {
        newErrors[key] = true;
        valid = false;
      } else if (value === "" || value === false) {
        newErrors[key] = true;
        valid = false;
      } else {
        newErrors[key] = false;
      }
    }

    setErrors(newErrors);

    if (valid) {
      UpdateUserStrategy();
    } else {
    }
  };

  const handleChangingFollow = (signal_followed, strategy_name) => {
    if (followingSettings !== "") {
      setModalProps({
        open: true,
        iconType: "warning",
        title: "A strategy is already being followed",
        message: `You are currently following strategy: ${followingSettings.strategy_followed}, do you want to stop following the current strategy and start following strategy: ${strategy_name}?`,
        onContinueLabel: "Continue",
        onContinue: () => handleUnfollow(signal_followed),
        onRefuseLabel: "Cancel",
        onRefuse: () => handleCloseNotification(),
      });
    }
  };

  const handleFollow = (signal_followed) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues, // Mantener los campos anteriores
      signal_followed: signal_followed, // Modificar solo el campo 'name'
    }));
    setModalData({
      open: true,
      iconType: "info",
      title: "Please, read carefully",
      onAcceptLabel: "Use default settings",
      onRefuseLabel: "Customize settings",
      modalType: "follow",
      onRefuse: () => handleFollowCustom(),
      onAccept: () => handleFollowDefault(),
    });
  };

  const handleUnfollow = (signal_followed = "") => {
    setModalProps({
      open: true,
      iconType: "warning",
      title: "Are you sure you want to unfollow this strategy?",
      message:
        "You are about to stop following this trading strategy. If you decide to unfollow, you will no longer automatically copy the trades of the selected trader.",
      onAcceptLabel: "Continue",
      onRefuseLabel: "Cancel",
      onRefuse: () => handleCloseNotification(),
      onAccept: () => RequestUnfollow(signal_followed),
    });
  };

  const handleFollowCustom = () => {
    setModalData({
      open: true,
      title: "Customize settings",
      onAcceptLabel: "Save and follow",
      onRefuseLabel: "Cancel",
      modalType: "followCustom",
      onAccept: () => handleSubmit(),
      onRefuse: () => handleModalClose(),
    });
  };

  const handleFollowDefault = () => {
    setModalData({
      open: true,
      title: "Default settings",
      onAcceptLabel: "Follow",
      onRefuseLabel: "Customize settings",
      modalType: "followDefault",
      onAccept: () => handleSubmit(),
      onRefuse: () => handleFollowCustom(),
    });
  };

  const handleModalClose = () => {
    setModalData({
      open: false,
    });
    setFormValues({
      copy_mode: "Proportional",
      risk_ratio: 1,
      drawdown_mode: "NoDrawdown",
      max_drawdown: 0,
      min_lot_size: 0,
      max_lot_size: 0,
      connect: true,
    });
  };

  const handleCloseNotification = (prevModalProps) => {
    setModalProps({ ...prevModalProps, open: false });
  };
  
  const handleClearFilters = (newAssets) => {
    setSelectedAssets([]);
    setSelectedCurrencies([]);
  };
  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleSearch = () => {
    try {
      setPage(1);
      const queryParams = new URLSearchParams();
  
      // Agregar strategy_name si hay una búsqueda
      if (search?.trim()) {
        queryParams.append("strategy_name", search);
      }
  
      // Agregar asset_type si hay activos seleccionados
      if (Array.isArray(selectedAssets) && selectedAssets.length > 0) {
        selectedAssets.forEach((asset) => {
          const assets = selectedAssets.join(",");
          queryParams.append("asset_type", assets);
        });
      }
  
      // Agregar instrument_type si hay monedas seleccionadas
      if (Array.isArray(selectedCurrencies) && selectedCurrencies.length > 0) {
        selectedCurrencies.forEach((currency) => {
          const instruments = selectedCurrencies.join(",");
          queryParams.append("instrument_type", instruments);
        });
      }
  
      // Agregar sort_by si está definido
      if (sortOrder?.trim()) {
        queryParams.append("sort_by", sortOrder.toLowerCase());
      }
  
      const queryString = queryParams.toString();
  
      if (queryString) {
        RequestSignals(12, page, queryString);
      } else {
        console.warn("No valid search criteria provided");
      }
    } catch (error) {
      console.error("Error while building search query:", error);
    }
  };

  const handleSearchPerPage  = () => {
    try {
      const queryParams = new URLSearchParams();
  
      // Agregar strategy_name si hay una búsqueda
      if (search?.trim()) {
        queryParams.append("strategy_name", search);
      }
  
      // Agregar asset_type si hay activos seleccionados
      if (Array.isArray(selectedAssets) && selectedAssets.length > 0) {
        selectedAssets.forEach((asset) => {
          const assets = selectedAssets.join(",");
          queryParams.append("asset_type", assets);
        });
      }
  
      // Agregar instrument_type si hay monedas seleccionadas
      if (Array.isArray(selectedCurrencies) && selectedCurrencies.length > 0) {
        selectedCurrencies.forEach((currency) => {
          const instruments = selectedCurrencies.join(",");
          queryParams.append("instrument_type", instruments);
        });
      }
  
      // Agregar sort_by si está definido
      if (sortOrder?.trim()) {
        queryParams.append("sort_by", sortOrder.toLowerCase());
      }
  
      const queryString = queryParams.toString();
  
      if (queryString) {
        RequestSignals(12, page, queryString);
      } else {
        console.warn("No valid search criteria provided");
      }
    } catch (error) {
      console.error("Error while building search query:", error);
    }
  };
  
  //#endregion

  //#region "Requests"
  const getRiskColor = (riskScore) => {
    if (riskScore >= 1 && riskScore <= 4)
      return ColorsVariables.Colors.Success_500;
    if (riskScore >= 5 && riskScore <= 7)
      return ColorsVariables.Colors.Warning_500;
    if (riskScore >= 8 && riskScore <= 10)
      return ColorsVariables.Colors.Primary_500;
    return ColorsVariables.Colors.Secondary_700; // Default color
  };

  const getRiskWidth = (riskScore) => {
    return (riskScore / 10) * 235; // Cada punto de riskScore es el 10% del ancho total
  };

  const RequestSignals = async (quantity, page, search = "") => {
    try {
      setLoading(true);
      const response = await followerServices.getSignals(
        quantity,
        page,
        search
      );
      if (response.data != null) {
        let responseData = response.data.data.data;
        const mappedData = responseData.map((item) => {
          return {
            signalLogin: item.signal_login,
            name: item.name,
            strategyName: item.strategy_name,
            description: item.description,
            feeValue: item.fee_value,
            //riskScore: item.risk_score,
            riskScore: item.risk_score,
            //totalFollowers: item.total_followers,
            totalFollowers: item.total_followers,
            asset: item.best_instrument_1.assetType,
            totalTrades: item.total_trades,
            visibility: item.visibility,
            drawdownMode: item.drawdown_mode,
            maxDrawdown: item.max_drawdown,
            disableAccountOnDrawdown: item.disable_account_on_drawdown,
            readOnlyWasTriggered: item.read_only_was_triggered,
            image:
              item.image && item.image.includes("/")
                ? url + "/" + item.image
                : "",
            totalReturn: 0,
            regDate: item.reg_date,
            currency: item.currency,
            accountType: item.account_type,
            strategyType: item.strategy_type,
            leverage: item.leverage,
            country: item.country,
            initialCopierBalance: item.initial_copier_balance,
            profitedTrades: item.profited_trades,
            lostTrades: item.lost_trades,
            performance: item.performance,
            best_instrument_1: item.best_instrument_1,
            best_instrument_2: item.best_instrument_2,
            best_instrument_3: item.best_instrument_3,
          };
        });

        setSignalList(mappedData);
        setPageCount(response.data.data.total_pages);
      } 
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setModalProps({
        open: true,
        iconType: "error",
        title: "Error getting strategies list",
        message:
          "An error has occurred in the request. Please try reloading, if the problem persists please contact an administrator",
        onAcceptLabel: "Continue",
        onAccept: () => handleCloseNotification(),
      });
    }
  };

  const UpdateUserStrategy = async () => {
    try {
      setLoading(true);
      const currentFormValues = formValuesRef.current;
      const response = await followerService.follow(currentFormValues);
      if (response.data != null) {
        SetFollowingSettings(response.data);
        handleSendCopyStatusEvent(response.data);
        navigate("/Follower/FollowingSettings");
      } 
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setModalProps({
        open: true,
        iconType: "error",
        title: "Error following strategy",
        message:
          "An error has occurred in the request. Please try reloading, if the problem persists please contact an administrator",
        onAcceptLabel: "Continue",
        onAccept: () => handleCloseNotification(),
      });
    }
    setLoading(false);
  };

  const TruncatedTypography = ({ text }) => {
    return (
      <Typography
        sx={Styles.AccountNameStyle}
        title={text}
      >
        {text}
      </Typography>
    );
  };

  const RequestUnfollow = async (signal_followed) => {
    try {
      setLoading(true);
      const response = await followerService.stopFollowing();
      if (response.data != null) {
        ClearFollowingSettings();
        setFollowingSettings("");
        handleSendCopyStatusEvent("");
        RequestSignals(12, page);
        if (signal_followed !== "") {
          handleCloseNotification();
          handleFollow(signal_followed);
        }
      }
    } catch (error) {
      setLoading(false);
      setModalProps({
        open: true,
        iconType: "error",
        title: "Error unfollowing strategy",
        message:
          "An unexpected error has occurred while the strategy was no longer followed, please contact an administrator",
        onAcceptLabel: "Continue",
        onAccept: () => handleCloseNotification(),
      });
    }
    setLoading(false);
  };
  //#endregion
  
  useEffect(() => {
    formValuesRef.current = formValues;
  }, [formValues]);

  useEffect(() => {
    const infoFollowing = GetFollowingSettings();
    if (infoFollowing.dataExits) {
      setFollowingSettings(infoFollowing.data);
    } else {
      setFollowingSettings("");
    }
  }, []);

  useEffect(() => {
    handleSearchPerPage();
  }, [sortOrder, page]); 

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "100%",
        justifyContent: "space-between",
        overflow: "hidden",
      }}
    >
      {loading ? <LoadingSpinner /> : <></>}
      <FollowingModal
        modalData={modalData}
        formValues={formValues}
        errors={errors}
        handleModalClose={handleModalClose}
        handleChange={handleChange}
      />
      <NotificationModal
        open={modalProps.open}
        onClose={handleCloseNotification}
        iconType={modalProps.iconType}
        title={modalProps.title}
        message={modalProps.message}
        onAcceptLabel={modalProps.onAcceptLabel}
        onAccept={modalProps.onAccept}
        onRefuseLabel={modalProps.onRefuseLabel}
        onRefuse={modalProps.onRefuse}
        onContinueLabel={modalProps.onContinueLabel}
        onContinue={modalProps.onContinue}
      />
      <Box sx={Styles.BoxPrincipalStyle}>
        {isXs && <StatusModule></StatusModule>}
        <FilterComponent
          sx={Styles.FilterComponentStyle}
          selectedAssets={selectedAssets}
          selectedCurrencies={selectedCurrencies}
          handleAssetChange={handleAssetChange}
          handleCurrencyChange={handleCurrencyChange}
          search={search}
          handleClearFilters = {handleClearFilters}
          handleSearchChange={handleSearchChange}
          handleSearch={handleSearch}
          handleFilterChange={handleFilterChange}
          sortOrder={sortOrder}
        />
        {/* Box Grid de las Cards*/}
        <Grid container spacing={2} sx={Styles.GridContainerStyle}>
          {signalList.map((card, index) => (
            <Grid
              item
              xs={12} // Ocupa todo el ancho en pantallas extra pequeñas
              sm={6} // Ocupa la mitad del ancho en pantallas pequeñas
              md={4} // Ocupa un tercio del ancho en pantallas medianas
              lg={3} // Ocupa un cuarto del ancho en pantallas grandes
              key={index}
              sx={{
                minWidth: 300, // Ancho mínimo para las tarjetas
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Card
                sx={
                  followingSettings.signal_followed === card.signalLogin
                    ? Styles.FollowCardStyle
                    : Styles.CardStyle
                }
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "18px",
                    padding: "0px",
                    "&:last-child": {
                      paddingBottom: "0px", // Sobrescribe el padding-bottom
                    },
                  }}
                >
                  {/* Zona Avatar y Total Return */}
                  <Box sx={Styles.ZoneAvatarAndTotalReturn}>
                    {/* Zona Avatar */}
                    <Box sx={Styles.ZoneAvatar}>
                      <img
                        src={card.image !== "" ? card.image : AvatarImg1}
                        alt={`Profile of ${card.name}`}
                        style={Styles.AvatarStyle}
                        loading="lazy"
                      />
                      <TruncatedTypography
                        sx={Styles.AccountNameStyle}
                        text={card.strategyName}
                      ></TruncatedTypography>
                    </Box>
                    {/* Zona Total Return */}
                    {/* <Box sx={Styles.ZoneTotalReturn}> */}
                      {/* <Typography sx={Styles.TotalReturnStyle}>
                        {t("follower.Total return")}
                      </Typography>
                      <Box sx={Styles.TotalReturnBoxStyle}>
                        <Typography sx={Styles.TotalReturnFontStyle}>
                          {card.totalReturn}%
                        </Typography>
                      </Box> */}
                    {/* </Box> */}
                  </Box>
                  {/* Zona de elementos de las Cards*/}
                  <Box sx={Styles.ZoneElements}>
                    {/* Fee y Drawdown*/}
                    <Box sx={Styles.ComboBasicElements}>
                      {/* Fee */}
                      <Box sx={Styles.BasicCardBox}>
                        <Typography sx={Styles.BasicCardTypoBox}>
                          {t("follower.Fee")}:
                        </Typography>
                        <Typography sx={Styles.BasicCardValueBox}>
                          {card.feeValue}%
                        </Typography>
                      </Box>
                      {/* Drawdown */}
                      <Box sx={Styles.BasicCardBox}>
                        <Typography sx={Styles.BasicCardTypoBox}>
                          {t("follower.Drawdown")}:
                        </Typography>
                        <Typography sx={Styles.BasicCardValueBox}>
                          {card.maxDrawdown}
                        </Typography>
                      </Box>
                    </Box>
                    {/* Followers y Symbols */}
                    <Box sx={Styles.ComboBasicElements}>
                      {/* Followers */}
                      <Box sx={Styles.BasicCardBox}>
                        <Typography sx={Styles.BasicCardTypoBox}>
                          {t("follower.Followers")}:
                        </Typography>
                        <Typography sx={Styles.BasicCardValueBox}>
                          {card.totalFollowers}
                        </Typography>
                      </Box>
                      {/* Symbol */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box
                            component="img"
                            src={
                              masterData.assets.find(
                                (asset) =>
                                  asset.label ===
                                  card.best_instrument_1.name.slice(0, 3)
                              )?.flag ||
                              masterData.assets.find(
                                (asset) => asset.label === "defaultFlagPath"
                              )?.flag
                            }
                            alt="asset 1"
                            sx={{
                              width: "18px",
                              height: "18px",
                              marginRight: "-8px",
                              borderRadius: "50%",
                            }}
                          />
                          <Box
                            component="img"
                            src={
                              masterData.assets.find(
                                (asset) =>
                                  asset.label ===
                                  card.best_instrument_1.name.slice(3)
                              )?.flag ||
                              masterData.assets.find(
                                (asset) => asset.label === "defaultFlagPath"
                              )?.flag
                            }
                            alt="asset 2"
                            sx={{
                              width: "18px",
                              height: "18px",
                              borderRadius: "50%",
                            }}
                          />
                        </Box>
                        <Box sx={Styles.BasicCardBox}>
                          <Typography sx={Styles.BasicCardTypoBox}>
                            {t("follower.Most traded")}:
                          </Typography>
                          <Typography
                            sx={{ fontFamily: TextVariables.Caption_0_bold }}
                          >
                            {card.best_instrument_1.name}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    {/* Risk Box */}
                    <Box>
                      <Box sx={Styles.RiskScoreBox}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "2px",
                            justifyContent: "space-between",
                            alignItems: "center",
                            alignSelf: "stretch",
                          }}
                        >
                          {/* Combo Typo y Value Del risk */}
                          <Box sx={Styles.BasicCardBox}>
                            <Typography sx={Styles.BasicCardTypoBox}>
                              {t("follower.Risk score")}:
                            </Typography>
                            <Typography sx={Styles.BasicCardValueBox}>
                              {card.riskScore}
                            </Typography>
                          </Box>
                          <Box sx={{ width: "20px", height: "20px" }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <mask
                                id="mask0_540_21006"
                                maskUnits="userSpaceOnUse"
                                x="0"
                                y="0"
                                width="20"
                                height="20"
                              >
                                <rect width="20" height="20" fill="#D9D9D9" />
                              </mask>
                              <g mask="url(#mask0_540_21006)">
                                <path
                                  d="M1.55432 17.0833L9.99974 2.5L18.4452 17.0833H1.55432ZM3.70807 15.8333H16.2914L9.99974 5L3.70807 15.8333ZM9.99974 14.8398C10.1904 14.8398 10.3503 14.7753 10.4793 14.6462C10.6083 14.5172 10.6729 14.3574 10.6729 14.1667C10.6729 13.976 10.6083 13.8161 10.4793 13.6871C10.3503 13.5581 10.1904 13.4935 9.99974 13.4935C9.80904 13.4935 9.64918 13.5581 9.52016 13.6871C9.39113 13.8161 9.32661 13.976 9.32661 14.1667C9.32661 14.3574 9.39113 14.5172 9.52016 14.6462C9.64918 14.7753 9.80904 14.8398 9.99974 14.8398ZM9.37474 12.6602H10.6247V8.49354H9.37474V12.6602Z"
                                  fill={getRiskColor(card.riskScore)}
                                />
                              </g>
                            </svg>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            height: "4px",
                            flexShrink: 0,
                            alignSelf: "stretch",
                            width: "100%",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="235px"
                            height="4"
                            fill="none"
                          >
                            <rect
                              width="235px"
                              height="4"
                              rx="2"
                              fill="#ECF1F7"
                            />
                            <rect
                              width={getRiskWidth(card.riskScore)}
                              height="4"
                              rx="2"
                              fill={getRiskColor(card.riskScore)}
                            />
                          </svg>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {/* Zona Botones Discover y Follow */}
                  <Box
                    sx={{
                      display: "flex",
                      height: "33px",
                      width: "100%",
                      alignItems: "flex-end",
                      gap: "12px",
                      justifyContent: "space-between",
                      padding: "0 12px",
                    }}
                  >
                    <Button
                      onClick={(e) => handleDiscoverMore(card.signalLogin)}
                      sx={{
                        display: "flex",
                        width: "99px",
                        height: "100%",
                        minWidth: "56px",
                        padding: "5px",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#15202A",
                        textAlign: "center",
                        fontFamily: TextVariables.Caption_1_Bold,
                        textTransform: "none",
                      }}
                    >
                      {t("follower.Discover more")}
                    </Button>
                    <Button
                      onClick={
                        followingSettings.signal_followed === card.signalLogin
                          ? (e) => handleUnfollow()
                          : followingSettings !== ""
                          ? (e) =>
                              handleChangingFollow(
                                card.signalLogin,
                                card.strategyName
                              )
                          : (e) => handleFollow(card.signalLogin)
                      }
                      sx={
                        followingSettings.signal_followed === card.signalLogin
                          ? Styles.unFollowButton
                          : Styles.followButton
                      }
                    >
                      {followingSettings.signal_followed === card.signalLogin
                        ? t("follower.Unfollow")
                        : t("follower.Follow")}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        {signalList.length > 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexGrow: 1,
              }}
            >
              <MuiPagination
                shape="rounded"
                count={pageCount}
                page={page}
                onChange={(event, newPage) => {
                  handleChangePage(newPage); // Cambiar la página actual
                }}
                sx={Styles.paginationStyle}
              />
            </Box>
          </Box>
        )}

        {signalList.length <= 0 && (
          <Box sx={Styles.performanceBox}>
            <img
              alt="background empty"
              src={backgroundImage}
              style={{ mixBlendMode: "luminosity", width: 350, opacity: 0.8 }}
            />
            <Typography sx={{ fontFamily: TextVariables.Caption_1 }}>
              There are no strategies that match the search.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
  //#endregion
}

export default DiscoverScreen;
