import React, { useEffect, useState } from "react";
import {Select,MenuItem,Typography,FormHelperText,} from "@mui/material";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import Colors from "../../../assets/Variables/ColorsVariables.js";
import { Spaces } from "../../../assets/Variables/SpaceVariables.js";

const TitledSelector = ({
  title,
  value,
  handleChange,
  options,
  error,
  helperText,
  placeholder,
  name
}) => {
  const selectStyle = {
    fontFamily: TextVariables.Body_4,
    borderRadius: Spaces.Size_2,
    border: error ? "1px solid red" : "0px solid",
    width: "100%",
    padding: "0px",
    border: "none", // Elimina el borde predeterminado
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none", // Asegúrate de que el borde del contorno también esté desactivado
    },
    border: error
      ? `1px solid ${Colors.Error_700}`
      : `1px solid ${Colors.Secondary_700}`,
  };
  return (
    <div>
      <Typography
        variant="h6"
        sx={{
          marginRight: "10px",
          marginBottom: "5px",
          fontFamily: TextVariables.Body_4,
        }}
      >
        {title}
      </Typography>
      <Select
        value={value}
        onChange={handleChange}
        sx={selectStyle}
        inputProps={{ name: name }}
        displayEmpty
      >
        <MenuItem value="" disabled>
          <Typography
            sx={{
              fontFamily: TextVariables.Body_4,
              color: Colors.Gray_400,
            }}
          >
            {placeholder}
          </Typography>
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={option.value}
            sx={{ fontFamily: TextVariables.Body_4 }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </div>
  );
};

export default TitledSelector;
